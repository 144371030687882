.line {
  position: relative;
}

.line::before {
  content: attr(data-line-number);
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.5;
  font-size: 0.7em;
  line-height: 1em;
  width: 3.5em;
  padding-top: 0.35em;
  padding-right: 0.5em;
  text-align: end;
  background-color: rgba(0, 0, 0, 0.08);
  border-right: 1px solid rgba(0, 0, 0, 0.5);
  height: 100%;
}

.line-text {
  margin-left: 3.5em;
}
