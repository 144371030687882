/**
 * Extra class applied to VirtualScroll through className prop
 */
.rst__virtualScrollOverride {
  overflow: visible !important;
}
.rst__virtualScrollOverride * {
  box-sizing: border-box;
}

.ReactVirtualized__Grid__innerScrollContainer {
}

.rst__rtl .ReactVirtualized__Grid__innerScrollContainer {
  direction: rtl;
}

.ReactVirtualized__Grid {
  outline: none;
}
