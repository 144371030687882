.image-block-editor {
  display: flex;
  overflow: hidden;
  width: 120px;
  height: 120px;
  border-radius: 4px;
  margin-bottom: 10px;
  background: rgba(0, 0, 0, 0.2);
  position: relative;
}

.image-block-editor-toolbar {
  position: absolute;
  display: flex;
  justify-content: end;
  width: 120px;
  background: rgba(0, 0, 0, 0.5);
  top: -100%;
  transition: all 0.15s ease-in-out;
}

.image-block-editor:hover .image-block-editor-toolbar {
  top: 0%;
}
