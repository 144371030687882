.html-editor-container {
  margin: 0.5em 0.25em;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.rdw-editor-toolbar {
  background: unset;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.rdw-editor-main {
  padding: 4px;
  overflow-x: hidden;
}

.rdw-image-modal-upload-option-label {
  word-break: break-word;
}
